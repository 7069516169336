import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "font-awesome/css/font-awesome.min.css";

import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

function ProjectCard(props) {
  const {
    imageSrc,
    title,
    heading,
    description,
    skills,
    liveDemoLink,
    visitLink,
    githubRepoLink,
  } = props.card;

  const reverseLayout = props.reversedLayout;
  const imageElements = imageSrc.map((image, index) => (
    <div class="carousel-item w-full aspect-video  ">
      <img
        key={index}
        src={image}
        draggable="false"
        className=" rounded-box border-2"
        alt="Tailwind CSS Carousel component"
      />
    </div>
  ));

  console.log(props);
  return (
    <div
      className={`flex vmax_1500:w-[150%] max_500:min-w-[200%]   justify-around space-x-7 bg-white bg-clip-border text-gray-700 shadow-md   text-center items-center  rounded-2xl card-shadow mt-24    max_1120:flex-col    relative    ${
        reverseLayout ? "flex-row-reverse" : ""
      } `}
    >
      <div className=" flex carousel max_1120:w-[80%] w-[70%] max_1500:w-[100%] ">
        {imageElements}
      </div>
      <div className="w-[40%]  flex flex-col items-center  max_1120:w-[85%]  p-3  m-2 ">
        {/* <h6 className="mb-4  max_1280:mb-1 text-5xl   block font-mulish font-bold uppercase leading-tight tracking-normal text-primary antialiased">
          {title}
        </h6> */}
        <h6 className="mb-4 max-w-4xl max_1280:text-5xl  text-4xl md:text-5xl block font-mulish font-bold uppercase leading-tight tracking-normal text-primary antialiased">
          {title}
        </h6>

        <h4 className="mb-4 block text-3xl   font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
          {heading}
        </h4>
        <p className="mb-2 block text-2xl max_1280:text-3xl max_1280:font-light font-normal leading-snug text-gray-700 antialiased">
          {description}
        </p>

        <div className="flex flex-row w-[160%] max_1120:scale-125 max_1120:w-[110%] justify-center text-center p-10 mb-2">
          <img
            src={`https://skillicons.dev/icons?i=${skills.join(",")}`}
            alt="skill-icon"
            className="max_1280:p-5 max_1280:-mt-8 w-[60%]"
          />
        </div>

        <div className="flex max_1120:scale-125 flex-row text-3xl justify-center space-x-10 items-center bg-white max_1280:-mt-8">
          <a
            href={liveDemoLink}
            target="_blank"
            rel="noreferrer"
            className="bg-blue-500 flex text-white px-4 max_1245:hidden py-2 rounded-lg hover:bg-blue-600"
          >
            <FontAwesomeIcon className="mr-2" icon={faLink} />
            Live Demo
          </a>
          <a
            href={visitLink}
            target="_blank"
            rel="noreferrer"
            className="bg-blue-500 hidden text-white px-4 max_1245:flex py-2 rounded-lg hover:bg-blue-600"
          >
            <FontAwesomeIcon className="mr-2" icon={faLink} />
            Visit
          </a>
          <a
            href={githubRepoLink}
            target="_blank"
            rel="noreferrer"
            className="bg-gray-500 text-white px-4 py-2 max_1245:hidden rounded-lg hover:bg-gray-600"
          >
            <FontAwesomeIcon className="mr-2" icon={faGithub} />
            GitHub Repo
          </a>
          <a
            href={githubRepoLink}
            target="_blank"
            rel="noreferrer"
            className="bg-gray-500 text-white px-4 py-2 max_1245:flex hidden rounded-lg hover:bg-gray-600"
          >
            <FontAwesomeIcon className="mr-2" icon={faGithub} />
            GitHub
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
