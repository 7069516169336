const data = [
  {
    title: "Blogging App",
    // imageSrc:
    //   "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80",
    imageSrc: [
      "https://firebasestorage.googleapis.com/v0/b/thumabnailsupport-blogapp.appspot.com/o/thumbnails%2Fblog-app2-0-vercel-app-1920x1080desktop-279b2d.pngd5c955e8-8239-40d8-a86b-1a7f5149695d?alt=media&token=27e0738d-b1d6-491e-81d3-98be9051e15b",
      "https://firebasestorage.googleapis.com/v0/b/thumabnailsupport-blogapp.appspot.com/o/thumbnails%2Fblog-app2-0-vercel-app-1920x1080desktop-5eaecc.pngd2a1c66a-4bd4-4927-be5c-16f65b60327f?alt=media&token=d0d8ac98-afb0-4ebb-a590-e200ee64ea55",
    ],

    heading: "Blogging App w/Authentication and CRUD functionality",
    database: "MongoDB",
    description:
      "Developed Blogging App with React, Node.js, MongoDB, and secure user authentication, offering seamless registration, login, and responsive experiences. Utilized React libraries for enhanced functionality and MongoDB Atlas for data storage and CRUD operations.",
    skills: ["mongodb", "react", "js", "express", "bootstrap"],
    liveDemoLink: "https://blog-app2-0.vercel.app/",
    visitLink: "https://blog-app2-0.vercel.app/",
    githubRepoLink: "https://github.com/BhaveshG-22/BlogApp2.0",
  },
  {
    imageSrc: [
      "https://firebasestorage.googleapis.com/v0/b/thumabnailsupport-blogapp.appspot.com/o/thumbnails%2FScreenshot%202023-10-14%20001444.png78a5fbaa-5242-4ce7-908c-8a8d2f6f893c?alt=media&token=cc666214-7e24-4ed2-b0fd-ecb0b1a6399d",
      "https://firebasestorage.googleapis.com/v0/b/thumabnailsupport-blogapp.appspot.com/o/thumbnails%2FScreenshot%202023-10-21%20190523%20(1).pngf3f4f99b-9127-4f2e-adda-4e81d533427d?alt=media&token=1d2981b5-219f-4445-ba13-a9d0e2723c21",
    ],
    title: "Multiplayer Game",
    heading: "Online Multiplayer Tic-Tac-Toe Socket.io",

    // database: "sql",
    description:
      "Dynamic multiplayer game created with Socket.IO and JavaScript, supporting up to 100 players. It demonstrates expertise in Socket.IO, WebSockets, Node, Express, React, and Postman, enabling seamless real-time communication and synchronized gameplay across devices.",
    skills: ["nodejs", "react", "express", "js", "bootstrap"],
    liveDemoLink: "https://online-tic-tac-toe-client.vercel.app/",
    visitLink: "https://online-tic-tac-toe-client.vercel.app/",
    githubRepoLink: "https://github.com/BhaveshG-22/OnlineTicTacToe-client",
  },
  {
    imageSrc: [
      "https://firebasestorage.googleapis.com/v0/b/thumabnailsupport-blogapp.appspot.com/o/thumbnails%2FScreenshot%202023-10-14%20001547.pngb47e938a-8bf3-46ba-bb6e-e797095acd71?alt=media&token=5bdc8936-8881-48cb-9bfa-5edcfa026aae",
    ],
    title: "Sudoku Solver",
    heading: "DOM Visualiser",
    // database: "sql",
    description:
      "Sudoku visualiser with real-time solving feature. Designed user interface using HTML, CSS, JS, and Bootstrap. Implemented Backtracking algorithm for solving logic. Utilized Node, Express, and NPM for development of puzzle generation functionalit. Demonstrates expertise in Algorithm and DOM Manipulation and problem-solving.",
    skills: ["", "js", "bootstrap", "", ""],
    liveDemoLink: "https://sudokusolvervisualiser.vercel.app/",
    visitLink: "https://sudokusolvervisualiser.vercel.app/",
    githubRepoLink: "https://github.com/BhaveshG-22/SudokuSolverVisualiser",
  },
];

export default data;
