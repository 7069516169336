import React from "react";

import project from "../data/projects";
import ProjectCard from "./projectCard_v2";

function Projects() {
  return (
    <div
      id="Project"
      className=" py-20 pl-52  pr-52 bg-body bg-project bg-projects  space-y-10  max_1020:prk-0 max_1020:pl-k0"
    >
      <div className="font-mulish pt-20 text-center  text-5xl text-gray-700 font-bold text-nav max_1020:ml-auto max_1020:mr-auto  ">
        PORTFOLIO
      </div>

      <div className="font-mulish text-5xl max_1020:text-center font-bold  max_1020:mr-auto  text-accent max_1020:ml-auto max_1020:max-w-[90%] max_1020:text-3xl max_1020:leading-10 ">
        Every project presented a unique opportunity for me to excell my
        existing skills and aquire new ones.
      </div>
      <div className="flex flex-col  justify-center text-center items-center font-poppins text-3xl max-w-[70%] mx-auto font-medium space-y-40 pt-14  text-accent">
        {project.map((project, index) => (
          <ProjectCard
            key={index}
            card={project}
            reversedLayout={index % 2 === 0 ? true : false}
          />
        ))}
      </div>
    </div>
  );
}

export default Projects;
