export default function RoundText() {
  return (
    // <span className="bg-[50%] mt-155 bg-no-repeat scale-110 rounded-full  relative transition-all duration-1000 ease-in-out   flex items-center justify-center h-[35rem] w-[35rem] max_500:h-[28rem] max_500:w-[28rem]  ">
    <span className="select-none  bg-no-repeat pmt-155   rounded-full  relative transition-all duration-1000 ease-in-out   flex items-center justify-center h-[35rem] w-[35rem] max_500:h-[28rem] max_500:w-[28rem]  ">
      {/* <div className="w-full ph-screen  border-primary rounded-full border-8"> */}
      <div className="w-full ph-screen rounded-full ring-8 border-home  border-[25px]  ring-gray-800  ring-inset  ">
        <div className="absolute-center ">
          <svg className="circle-svg" viewBox="0 0 500 500">
            <defs>
              <path
                d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250"
                id="textcircle_top"
              >
                <animateTransform
                  attributeName="transform"
                  begin="0s"
                  dur="20s"
                  type="rotate"
                  from="0 250 250"
                  to="360 250 250"
                  repeatCount="indefinite"
                />
              </path>
            </defs>
            <text className="circle-text" dy="30" textLength="1210">
              <textPath
                xlinkHref="#textcircle_top"
                className="animate-spin text-7xl font-poppins "
              >
                LEARN ‎‎‎‎>‎‎‎‎BUILD‎‎‎‎>‎‎‎‎REPEAT‎‎‎‎>‎‎‎‎
              </textPath>
            </text>
          </svg>
        </div>
      </div>

      {/* <div className="  text-9xl absolute">🧑‍💻</div> */}
      {/* <div className="  text-9xl absolute"> &#128187;</div> */}
      <div className="text-9xl absolute">
        <img src="/homeLaptopIcon.png"></img>
      </div>
    </span>
  );
}
